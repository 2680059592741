import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import routes from "../routes";
import './DefaultLayout.module.scss';
import styles from './DefaultLayout.module.scss';
/*-- Imports for redux --*/
import { useSelector } from 'react-redux';
import { RootState, useReduxDispatch } from '../redux/store';
import { updateResponsive, resetResponsive } from '../redux/responsive/responsiveAction';

/*-- Import dependencies --*/
import LoadingScreen from '../components/common/loadingScreen/LoadingScreen';

import { responsiveScreenSizesEnum } from '../utilities/enums';
import DefaultHeader from './DefaultHeader';
import DefaultBreadcrumb from './DefaultBreadcrumb';
import { CHeader } from '@coreui/react';
import Sidebar from './Sidebar';
import { Container } from 'reactstrap';

function DefaultLayout() {
    const showLoadingScreen = useSelector((state: RootState) => state.loadingScreen.show);
    const dispatch = useReduxDispatch();
    const userSessionData = useSelector((state: RootState) => state.UserSession);
    const Login = React.lazy(() => import('../pages/Login/Login'));

    useEffect(() => {
        responsiveFn();
    });

    function loadingScreen() {
        return <LoadingScreen show={true} />
    }

    function responsiveFn(): any {
        if (window.outerWidth <= responsiveScreenSizesEnum.mobileSmallScreen) {
            let screen = { isTabScreen: false, isMobileLargeScreen: false, isMobileSmallScreen: true }
            dispatch(updateResponsive(screen));
        } else if (window.outerWidth <= responsiveScreenSizesEnum.mobileLargeScreen) {
            let screen = { isTabScreen: false, isMobileLargeScreen: true, isMobileSmallScreen: false }
            dispatch(updateResponsive(screen));
        } else if (window.outerWidth <= responsiveScreenSizesEnum.tabScreenSize) {
            let screen = { isTabScreen: true, isMobileLargeScreen: false, isMobileSmallScreen: false }
            dispatch(updateResponsive(screen));
        } else {
            dispatch(resetResponsive());
        }
    }

      
    return (
        <React.Fragment>
            <div className="app">
                    <DefaultHeader />
                    <div className={`${styles.test}`}>
                            <DefaultBreadcrumb routes = {routes}/>
                            <Container fluid>
                            <BrowserRouter>
                                <div className={`${styles.outer}`}>
                                    <Suspense fallback={loadingScreen()}>
                                        <Switch>
                                            {routes.map((route, index) => {
                                                return route.component ? (
                                                    <Route 
                                                        key={route.id} 
                                                        path={route.path} 
                                                        render={(props) => <route.component {...props} />}
                                                    />
                                                ) : (null);
                                            })}
                                            <Redirect from="/" to="/home" />
                                        </Switch>
                                    </Suspense>
                                </div>
                            </BrowserRouter>
                            </Container>
                        </div>
            </div>
            <LoadingScreen show={showLoadingScreen} />
        </React.Fragment>
    )
}

export default DefaultLayout;