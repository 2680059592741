import React, { FC, useEffect, useState } from 'react';
import { AiFillCaretDown, AiFillCaretLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import './DefaultBreadcrumb.scss';

interface iroute {
    path: string
    name: string,
    component: any
}

type RoutesProps = {
    routes: iroute[];
};

const DefaultBreadcrumb: FC<RoutesProps> = ({ routes }) => {

    const [CurrentPathName, setCurrentPath]: any = useState([]);

    useEffect(() => {
        routes.forEach(route => {
            if (window.location.pathname.split('/')[1] == route.path.split('/')[1]) {
                setCurrentPath(route.name);
            }
        })
    })

    return (
        <div id="default_breadcrumb_id">
            <a href="/dashboard">
                <div className="default-breadcrumb-home">
                    <i className="fas fa-home"></i>
                </div>
            </a>
            <div id="default-breadcrumb-arrow_id" style={{float: 'left', display: 'block'}}>
                <i className="fas fa-caret-right default-breadcrumb-arrow"></i>
            </div>
            <div className="default-breadcrumb-item"><a>{CurrentPathName}</a></div>
        </div>
    );
};

export default DefaultBreadcrumb;