let BACKEND_URL = 'URLMissing';
let LOGIN_URL = 'URLMissing';
let API_VERSION = '1.0'

const hostname = window && window.location && window.location.hostname;

switch (hostname) {
    case 'spark.keellssuper.net':
        BACKEND_URL = 'https://sparkbackend.keellssuper.net';
        LOGIN_URL = "https://xena.keellssuper.net";
        break;
    case 'b2b-admin-qa.keellssuper.net':
        BACKEND_URL = 'https://b2b-admin-qa-backend.keellssuper.net';
        LOGIN_URL = "https://xena-qa.keellssuper.net";
        break;
    case 'spark-uat.keellssuper.net':
        BACKEND_URL = 'https://sparkbackend-uat.keellssuper.net';
        LOGIN_URL = "https://xena-uat.keellssuper.net";
        break;
    case 'localhost':
        BACKEND_URL = 'http://localhost:33291';
        LOGIN_URL = "http://localhost:3339";
        break;
    default:
        console.error('Hosted server name is missing in the environment-config file.');
        break;
}

export { BACKEND_URL, LOGIN_URL, API_VERSION };